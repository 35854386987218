<template>
    <h2 class="site__title" v-bind:class="addedClass">
        <!--addedClass: -->
        <!--site__title_duplicate: add shadow under title-->
        <!--site__title_left: add style text-align: left; on desktop-->
        <!--site__title_2: dark color for shadow text-->
        <!--site__title_3: white color for shadow text-->

        <span class="site__title-original">
            <slot></slot>
        </span>
    </h2><!-- /site__title -->
</template>

<script>
    export default {
        name: "SiteTitle",
        props: ['addedClass'],
        mounted () {
            this.addShadow();
        },
        updated: function () {
            this.addShadow();
        },
        methods: {
            addShadow() {
                const curElem = this.$el,
                    child = curElem.querySelectorAll('.site__title-shadow');
                for ( let i = 0; i < child.length; i++ ){
                    curElem.removeChild(child[i]);
                }
                if (curElem.classList.contains('site__title_duplicate')) {
                    let newElem = document.createElement('span');
                    newElem.innerHTML = curElem.innerHTML;
                    newElem.classList.add('site__title-shadow');
                    curElem.insertBefore(newElem,curElem.childNodes[0]);
                }

            }
        },
    }
</script>

<style scoped></style>